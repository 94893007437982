/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* layout-large.less 2013-1-16 *******/
.cb-page-layout2 .desk {
  width: 79.84375%;
  max-width: 1022px;
}
.maincontent {
  padding-top: 109px;
  padding-bottom: 91px;
}
.cb-page-layout1.cb-page-color-c3.cb-page-first .maincontent {
  padding-top: 50px;
}
.cb-page-layout2 .maincontent {
  padding-top: 148px;
  padding-bottom: 148px;
}
.cb-page-layout1 .main {
  width: 100%;
}
.cb-page-layout1 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout1 .main h2,
.cb-page-layout1 .main .foot {
  margin-right: 1.43198091%;
  margin-left: 1.43198091%;
}
.cb-page-layout1 .main .part,
.cb-page-layout1 .main > .grid table {
  margin-right: 1.43198091%;
  margin-left: 1.43198091%;
  width: 97.13603819%;
}
.cb-page-layout1 .main .tiny {
  width: 22.13603819%;
}
.cb-page-layout1 .main > .slim {
  width: 50%;
}
.cb-page-layout1 .main > .slim h2,
.cb-page-layout1 .main > .slim .foot,
.cb-page-layout1 .main > .slim .part,
.cb-page-layout1 .main > .slim.grid table {
  margin-right: 2.86396181%;
  margin-left: 2.86396181%;
}
.cb-page-layout1 .main > .slim .part,
.cb-page-layout1 .main > .slim.grid table {
  width: 94.27207637%;
}
.cb-page-layout1 .main > .slim .tiny {
  width: 94.27207637%;
}
.cb-page-layout2 .main {
  width: 100%;
}
.cb-page-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout2 .main h2,
.cb-page-layout2 .main .foot {
  margin-right: 17.38410596%;
  margin-left: 17.38410596%;
}
.cb-page-layout2 .main .part,
.cb-page-layout2 .main > .grid table {
  margin-right: 17.38410596%;
  margin-left: 17.38410596%;
  width: 65.23178808%;
}
.cb-page-layout2 .main > .slim .part,
.cb-page-layout2 .main > .slim.grid table {
  width: 65.23178808%;
}
.cb-page-layout2 .side {
  width: 100%;
}
.cb-page-layout2 .side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout2 .side h2,
.cb-page-layout2 .side .foot {
  margin-right: 15.2284264%;
  margin-left: 15.2284264%;
}
.cb-page-layout2 .side .part,
.cb-page-layout2 .side > .grid table {
  margin-right: 15.2284264%;
  margin-left: 15.2284264%;
  width: 69.54314721%;
}
.cb-page-layout2 .side > .slim .part,
.cb-page-layout2 .side > .slim.grid table {
  width: 69.54314721%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.cb-page-layout1 .area {
  width: 102.94840295%;
  margin-left: -1.47420147%;
}
.cb-page-layout2 .main {
  width: 59.09980431%;
}
.cb-page-layout2 .side {
  float: right;
  margin-top: 150px;
  width: 38.5518591%;
}
#edit .side {
  padding: 0;
  border: 0;
}
.fold:before,
.fold:after,
.seam:before,
.seam:after {
  left: -12.77641278%;
  right: -12.77641278%;
}
.cb-page-layout1 .wide .body {
  text-align: center;
}
.cb-page-layout1 .wide .part {
  float: none;
  text-align: left;
  display: inline-block;
  font-size: 16px;
  font-size: 1.6rem;
}
.cb-page-layout2 .unit {
  padding: 49px 0 60px;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */